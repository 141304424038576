export const userDetails = [
  {
    id: "1",
    img: "https://e7.pngegg.com/pngimages/550/997/png-clipart-user-icon-foreigners-avatar-child-face.png",
    name: "Ankit",
    dob: "25 Feb 2001",
  },
  {
    id: "2",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0YtOC-DWcKhaIbwJDRuAlgKJKugPwp5dfhKKgOJf_UDtKQdOeZq9CQEetxDF1jmntumU&usqp=CAU",
    name: "Ankur",
    dob: "25 Feb 2000",
  },
  {
    id: "3",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaqJCLRz8N0Ci79cQRXUO6mIi6Lu4HSf031Q6eI2aZ7SfGrNa7y9QpbCToKMxRMWfMtLA&usqp=CAU",
    name: "Jay",
    dob: "15 Jan 1998",
  },
  {
    id: "4",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyqWVNJQSK2knvyCuhG8w6si1JvC2_ufqKOkWMqwKAtWNVnx4uIHNvffuCihbgSNl1ZbM&usqp=CAU",
    name: "Rohan",
    dob: "07 Mar 1996",
  },
  {
    id: "5",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxWJBqOVdj_-pclX9dOh7MyReLMpMDb7F3HwqJZWYufuDoMIrWRdQswtYFLgh0e_Jp9RY&usqp=CAU",
    name: "Hemang",
    dob: "17 Dec 1999",
  },
];
