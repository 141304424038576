import React from 'react'

const Home = () => {
  return (
    <>
      <div className='flex justify-center items-center py-52 md:py-64'>
        <img src="welcome.gif" alt='Welcome Page'/>
        {/* <h1 className='font-Pacifico text-2xl md:text-5xl font-bold'>Welcome Page</h1> */}
      </div>
    </>
  )
}

export default Home
